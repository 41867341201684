import { Action } from '@ngrx/store';
import { type } from '../../../util';
import { IProfilePhotoResponse } from '../../../core/services/profilePhoto/profilePhoto.service';

export const ActionTypes = {
  PHOTO_FETCH: type('[Photo] fetch profile photo task'),
  FETCH_PHOTO_STATUS: type('[Photo] fetch profile photo status'),
  PHOTO_STORE: type('[Photo] store server response'),
  PHOTO_CHANGE: type('[Photo] photo loaded by user'),
  PHOTO_CONFIRM: type('[Photo] confirm photo'),
};

export class FetchProfilePhotoTask implements Action {
  public type = ActionTypes.PHOTO_FETCH;

  constructor(public payload?: any) {}
}

export class FetchProfilePhotoStatus implements Action {
  public type = ActionTypes.FETCH_PHOTO_STATUS;

  constructor(public payload?: any) {}
}

export class StorePhotoData implements Action {
  public type = ActionTypes.PHOTO_STORE;

  constructor(public payload: IProfilePhotoResponse) {}
}

export class PhotoChange implements Action {
  public type = ActionTypes.PHOTO_CHANGE;

  constructor(public payload: string) {}
}

export class ConfirmPhoto implements Action {
  public type = ActionTypes.PHOTO_CONFIRM;

  constructor(public payload: string) {}
}

export type TypeAction =
  FetchProfilePhotoTask |
  StorePhotoData |
  PhotoChange |
  ConfirmPhoto;
